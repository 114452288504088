import React, { useState, useEffect } from "react";
import DataTable from "../DataTable";
// import AddPromoModal from "./AddPromoModal";
import { useLocation, Link } from "react-router-dom";
import "../../index.css";
import Sidebar from "../Sidebar";
import Layout from "../Layout";
import EditInventory from "./EditInventory";
import { gql, useMutation, useQuery } from "@apollo/client";
import Loader from "../../Loader";
const DELETE_INVENTORY = gql`
  mutation deleteInventory($data: ID!) {
    deleteInventory(data: $data) {
      status
      message
    }
  }
`;

const GET_INVENTORY = gql`
  query {
    getInventory {
      message
      status
      inventoryList {
        id
        name
        description
        canDecreased
      }
    }
  }
`;

const InventoryTable = () => {
  const { loading, error, data } = useQuery(GET_INVENTORY);
  const [openModal, setOpenModal] = useState(false);
  const [dataList, setDataList] = useState([]);
  const [isFetched, setIsFetched] = useState(false);
  const [id, setid] = useState("");

  const [deleteInventory, { loading: prodDeletLoading, error: producterror }] =
    useMutation(DELETE_INVENTORY);

  const [editForm, setEditForm] = useState(false);
  const [editData, setEditData] = useState({});
  useEffect(() => {
    try {
      console.log("error is");
      console.log(producterror);
      console.log("data is");
      console.log(data.getInventory);
      setDataList(data.getInventory.inventoryList);
      setIsFetched(true);
    } catch (err) {
      console.log(err);
    }
  }, [data]);
  // const [deletePromocodeMutation, {loading, error}]= useMutation(DELETE_PROMOCODE,
  //   {
  //     variables:{
  //       id: id
  //     }
  //   })
  // function deletePromocodeMutation(event) {
  //   let dataId= event.target.getAttribute("data-deleteId")
  //   alert(dataId);
  //   const { data, loading, error } = useMutation(DELETE_PROMOCODE, {
  //           variables:{
  //             id: dataId
  //           }
  //   });

  const handleRemoveRow = (dataId) => {
    console.log(dataId);
    deleteInventory({
      variables: { data: dataId },
    }).then((res) => {
      // window
      console.log(" deleteInventory res is");
      console.log(res);
      if (res.data.deleteInventory.status === "SUCCESS") {
        window.location.reload();
      }
    });
  };

  const handleEditClick = (data) => {
    setEditData(data);

    setEditForm(true);
  };
  if (loading || prodDeletLoading || !isFetched) return <Loader />;
  return (
    <>
      {isFetched &&
        (editForm ? (
          <EditInventory editFormData={editData} />
        ) : (
          <div className=" h-screen flex">
            <Sidebar />
            <Layout>
              <div className="flex flex-col space-y-6 pt-8 ">
                <div className="flex justify-between items-center">
                  <div className="flex text-[#FBB033] items-center font-be-jost-pro text-xl font-bold space-x-3 pt-8 pl-5">
                    <Link
                      to="/admin/services"
                      className="text-[#FBB034] hover:bg-white hover:text-[#00395E] rounded-sm cursor-pointer text-base px-2 py-1 font-semibold font-be-jost-pro text-center md:text-left"
                    >
                      <span> Services</span>
                    </Link>

                    <Link
                      to="/admin/category"
                      className=" text-[#FBB034] hover:bg-white hover:text-[#00395E] rounded-sm cursor-pointer text-base px-2 py-1 font-semibold font-be-jost-pro text-center md:text-left"
                    >
                      <span>Category</span>
                    </Link>
                    <Link
                      to="/admin/sub-category"
                      className=" text-[#FBB034] hover:bg-white hover:text-[#00395E] rounded-sm cursor-pointer text-base px-2 py-1 font-semibold font-be-jost-pro text-center md:text-left"
                    >
                      <span>Sub Category</span>
                    </Link>
                    <p className="hover:bg-white hover:text-[#00395E] rounded-sm cursor-pointer text-base px-2 py-1 font-semibold font-be-jost-pro text-center md:text-left bg-white text-[#00395E]">
                      Inventory
                    </p>
                  </div>
                  <div>
                    <Link
                      to="/admin/inventory/create"
                      className="font-be-jost-pro font-medium text-sm border border-[#FBB033] text-[#FBB033] rounded-md px-5 py-2"
                    >
                      Add Inventory
                    </Link>
                  </div>
                </div>
                <div>
                  <DataTable tableName="Inventory">
                    <thead>
                      <tr>
                        <th data-priority="1" className="text-white">
                          Name
                        </th>
                        <th data-priority="2" className="text-white">
                          Descriprion
                        </th>
                        <th data-priority="3" className="text-white">
                          Can Decrease
                        </th>

                        <th data-priority="5" className="text-white">
                          Actions
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {dataList.map((row) => {
                        return (
                          <tr className="border-b-2 border-b-[#E0E0E0]">
                            <td className="text-white w-[10%] px-1 border-b-[1px] border-b-[#E0E0E0]">
                              {row.name}
                            </td>

                            <td className="w-[8%] border-b-[1px] border-b-[#E0E0E0]">
                              {row.description}
                            </td>

                            <td className="text-white w-[7%] text-center border-b-[1px] border-b-[#E0E0E0]">
                              {row.canDecreased !== null &&
                                (row.canDecreased == true ? "Yes" : "No")}
                            </td>

                            <td className="w-[10%] text-center px-2 border-b-[1px] border-b-[#E0E0E0]">
                              <div className="flex flex-col lg:flex-row space-y-2 lg:space-y-0 items-center justify-center h-full lg:space-x-2">
                                <button
                                  className="rounded-md bg-white py-2 px-3 text-black text-sm font-medium"
                                  onClick={() => handleEditClick(row)}
                                >
                                  Edit
                                </button>
                                <button
                                  //  onClick={e=> {e.preventDefault(); deleteProduct({ variables:{ id: row.id}});window.location.reload() }} data-deleteId= {row.id}
                                  onClick={() => handleRemoveRow(row.id)}
                                  className="rounded-md bg-white py-2 px-3 text-black text-sm font-medium"
                                >
                                  Delete
                                </button>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </DataTable>
                </div>
                {/* {openModal && <AddPromoModal setShowModal={setOpenModal} />} */}
              </div>
            </Layout>
          </div>
        ))}
    </>
  );
};

export default InventoryTable;
