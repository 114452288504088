import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { IoMdCloudUpload } from "react-icons/io";
import { AiOutlineDelete } from "react-icons/ai";
import Sidebar from "../Sidebar";
import Layout from "../Layout";
import "../../index.css";
import { useQuery, gql, useMutation } from "@apollo/client";
import axios from "axios";

const UPDATE_INVENTORY = gql`
  mutation updateInventory($data: updateInventoryInput!) {
    updateInventory(data: $data) {
      status
      message
    }
  }
`;

const EditInventory = ({ editFormData }) => {
  const navigate = useNavigate();

  let decreaseValue = editFormData.canDecrease == true ? "true" : "false";

  const [updateInventory, { addLoading, addError }] =
    useMutation(UPDATE_INVENTORY);
  const [name, setName] = useState(editFormData.name);

  const [category, setCategory] = useState(editFormData.canDecreased);

  const [canDecrease, setCanDecrease] = useState(editFormData.canDecreased);

  const [dataCategoryList, setDataCategoryList] = useState([]);

  const [isFetched, setIsFetched] = useState(false);
  const [description, SetDesciption] = useState(editFormData.description);

  useEffect(() => {
    try {
      setIsFetched(true);
    } catch (err) {
      console.log(err);
    }
  }, []);

  const onCategoryChange = (e) => {
    setCategory(e.target.value);
    let boolValue = e.target.value === "true" ? true : false;
    setCanDecrease(boolValue);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let finalDataObj = {};
    finalDataObj.id = editFormData.id;
    finalDataObj.name = name;
    finalDataObj.description = description;
    finalDataObj.canDecreased = category;
    console.log(finalDataObj);
    updateInventory({
      variables: {
        data: finalDataObj,
      },
    })
      .then((res) => {
        console.log("updateInventory res is");
        console.log(res);
        if (res.data.updateInventory.status === "SUCCESS") {
          window.location = "/admin/inventory-table";
          // navigate("/admin/sub-category");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // const subCategoryClass = subCategory ? "text-white" : " text-[#9A9A9A]";
  const categoryClass = category ? "text-white" : " text-[#9A9A9A]";

  return (
    <>
      <div className=" h-screen flex">
        <Sidebar />
        <Layout>
          {isFetched && (
            <div className="flex flex-col py-3 px-2 space-y-5">
              <h3 className="text-[#FBB033] font-be-jost-pro font-bold text-2xl">
                Edit Inventory
              </h3>
              <div className="w-full flex flex-col space-y-8">
                <input
                  type="text"
                  placeholder="Name*"
                  className="px-2 py-1 bg-[#0c0b0b] border-b-[1px] border-b-[#E0E0E0] outline-none text-white"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                <select
                  placeholder="Category*"
                  className={`px-2 py-1 bg-[#0c0b0b] border-b-[1px] border-b-[#E0E0E0] outline-none ${categoryClass}`}
                  value={category}
                  onChange={onCategoryChange}
                >
                  <option value="" disabled hidden>
                    Can decrease
                  </option>

                  <option value="true">Yes</option>
                  <option value="false">No</option>
                  {/* {
            dataCategoryList.map((item,i)=>(
              <option value={item.id}>{item.name}</option>
            ))
          } */}
                </select>

                <input
                  type="text"
                  placeholder="Description*"
                  className="px-2 py-1 bg-[#0c0b0b] border-b-[1px] border-b-[#E0E0E0] outline-none text-white"
                  value={description}
                  onChange={(e) => SetDesciption(e.target.value)}
                />
                <div className="flex items-center justify-center">
                  <button
                    className="bg-[#FBB034] text-[#202125] py-2 px-6 rounded-md font-poppins text-sm font-bold"
                    onClick={handleSubmit}
                  >
                    Update Inventory
                  </button>
                </div>
              </div>
            </div>
          )}
        </Layout>
      </div>
    </>
  );
};

export default EditInventory;
