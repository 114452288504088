import React, { useState } from "react";
import DataTable from "../DataTable";
import {gql,useMutation} from '@apollo/client'
import { useNavigate } from "react-router-dom";


const DELETE_CUSTOMER = gql`
mutation deleteCustomer($id: String!){
  deleteCustumer(data:{
    id:$id
  }){
    status
    message
  }
}
`

const CustomerManagementTable = ({ data }) => {
  console.log('GET_CUSTOMERS');
  const navigate = useNavigate();

  console.log(data);
  const [editForm, setEditForm] = useState(false);
  const [id,setid] = useState("");
  let finaladdress="";
  const [deleteCustomerMutation, { loading, error }] = useMutation(DELETE_CUSTOMER);
  const handleRowClick = (userData) => {
    navigate(`/admin/user-dashboard/${userData.id}`, {state: {userData}});
  };

  return (
    <>
      <div>
        <DataTable tableName="User-managment">
          <thead>
            <tr>
              <th data-priority="1" className="text-white">
                Name
              </th>
              <th data-priority="2" className="text-white">
                Mobile Number
              </th>
              <th data-priority="3" className="text-white">
                Address
              </th>
              <th data-priority="4" className="text-white">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {/* <tr> */}
            {data.map((row) => {
              if(row.address){
               finaladdress = `${row.address.line1} ${row.address.line2} ${row.address.landmark} ${row.address.city} ${row.address.state} ${row.address.country}`
              }
              return (
                // ONCLIK OF IT WE NEED CUSTOMER DASHBOARD.JSX - > INPUT(ROW)
                //customer_dashboard.jsx -> row -> userId -> getUserDashboard(userId, startDate, endDate)
                <tr className="border-b-2 border-b-[#E0E0E0]"
                key={row.id}
                onClick={() => handleRowClick(row)}>
                  <td className="text-white w-[10%] px-1 border-b-[1px] border-b-[#E0E0E0]">
                    {row.fullName}
                  </td>
                  <td className="text-white w-[7%] text-center border-b-[1px] border-b-[#E0E0E0]">{`${row.contactNumber}`}</td>
                  {/* <td className="text-white w-[7%] text-center border-b-[1px] border-b-[#E0E0E0]">{`${finaladdress}`}</td> */}
                  <td className="text-white w-[7%] text-center border-b-[1px] border-b-[#E0E0E0]">{
                    row.address !== null ?
                    (
                      row.address[0].city
                    )
                    :"no Address"
                  }</td>
                  <td className="w-[10%] text-center px-2 border-b-[1px] border-b-[#E0E0E0]">
                    <div className="flex flex-col lg:flex-row space-y-2 lg:space-y-0 items-center justify-center h-full lg:space-x-2">
                      <button onClick={e=> {e.preventDefault();alert(row.id); deleteCustomerMutation({ variables:{ id: row.id}}) }} className="rounded-md bg-white py-2 px-3 text-black text-sm font-medium">
                        Delete
                      </button>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </DataTable>
      </div>
    </>
  );
};

export default CustomerManagementTable;
