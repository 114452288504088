import React from "react";
import { useQuery, gql } from "@apollo/client";
import "./UserDashboard.css";
import { useParams, useLocation } from "react-router-dom";
import { useState } from "react"; // Import useParams for accessing route parameters
import Loader from "./Loader";
// Define the GraphQL Query
const GET_USER_DASHBOARD = gql`
  query getUserDashboard($data: UserDashboardInput!) {
    getUserDashboard(data: $data) {
      status
      message
      totalServices {
        id
        price
        totalPrice
        address
        bookingDate
        paymentType
        status
        timeSlot
        totalTime
        bookingId
        orderDetail {
          id
          name
          price
        }
        employee {
          id
          fullName
        }
      }
    }
  }
`;

// React Component
const UserDashboard = () => {
  // Fetch the data using useQuery
  const { userId } = useParams();
  const location = useLocation();
  const { userData } = location.state;
  console.log(userData);

  // State for search filter
  const [searchBookingId, setSearchBookingId] = useState("");

  // Filtered services based on booking ID

  const { loading, error, data } = useQuery(GET_USER_DASHBOARD, {
    variables: {
      data: { userId },
    },
    enabled: Boolean(userId),
  });

  if (loading) return <p><Loader/></p>;
  if (error) return <p>Error: {error.message}</p>;

  // Extract data from the response
  const { status, message, totalServices } = data.getUserDashboard;

  // Map the totalServices into usable objects
  
  console.log(Array.isArray(totalServices));
  const services = totalServices.map((service) => ({
    id: service?.id ?? "N/A", // If id is null or undefined, use 'N/A'
    price: service?.price ?? 0, // Default price to 0 if null or undefined
    totalPrice: service?.totalPrice ?? 0, // Default total price to 0 if null or undefined
    address: service?.address ?? "Not Provided", // Default address if null or undefined
    bookingDate: service?.bookingDate ?? "Not Available", // Default value if booking date is missing
    paymentType: service?.paymentType ?? "Not Specified", // Default value for payment type
    status: service?.status ?? "Unknown", // Default status if null or undefined
    timeSlot: service?.timeSlot ?? "Not Available", // Default time slot if null or undefined
    totalTime: service?.totalTime ?? "Not Available", // Default total time if null or undefined
    bookingId: service?.bookingId ?? "Not Available", // Default booking id if null or undefined
    orderDetail:
      service?.orderDetail?.map((detail) => ({
        id: detail?.id ?? "N/A", // Default order ID if null or undefined
        name: detail?.name ?? "Unknown", // Default name if null or undefined
        price: detail?.price ?? 0, // Default price if null or undefined
      })) ?? [], // Default to empty array if orderDetail is missing
    employee: {
      id: service?.employee?.id ?? "N/A", // Default employee ID if null or undefined
      fullName: service?.employee?.fullName ?? "Unknown", // Default employee full name
    },
  }));

  const filteredServices = services.filter((service) =>
    service.bookingId.toLowerCase().includes(searchBookingId.toLowerCase())
  );

  // Handle input change for filter
  const handleSearchChange = (e) => {
    setSearchBookingId(e.target.value);
  };

  return (
    <div className="dashboard-container">
      <h1>User Dashboard</h1>
      <h2>
        <strong>Name: </strong> {userData.fullName}
      </h2>
      <h2>
        <strong>Contact Number: </strong> {userData.contactNumber}{" "}
      </h2>

      {/* Filter by Booking ID */}
      <div className="filter-container">
        <label htmlFor="booking-id-filter">Filter by Booking ID: </label>
        <input
          type="text"
          id="booking-id-filter"
          value={searchBookingId}
          onChange={handleSearchChange}
          placeholder="Enter Booking ID"
        />
      </div>

      {/* Display filtered services */}
      <div className="services-grid">
        {filteredServices.length > 0 ? (
          filteredServices.map((service) => (
            <div key={service?.id} className="service-card">
              <h3>
                {" "}
                <strong>Booking ID: </strong>
                {service?.bookingId}
              </h3>
              <p>
                <strong>Price:</strong> ₹{service?.price}
              </p>
              <p>
                <strong>Total Price:</strong> ₹{service?.totalPrice}
              </p>
              <p>
                <strong>Address:</strong> {service?.address}
              </p>
              <p>
                <strong>Booking Date:</strong> {service?.bookingDate}
              </p>
              <p>
                <strong>Payment Type:</strong> {service?.paymentType}
              </p>
              <p>
                <strong>Status:</strong> {service?.status}
              </p>
              <p>
                <strong>Time Slot:</strong> {service?.timeSlot}
              </p>
              <p>
                <strong>Total Time:</strong> {service?.totalTime}
              </p>

              <div className="order-details">
                <h4>Order Details</h4>
                {service.orderDetail?.length > 0 ? (
                  <ul>
                    {service.orderDetail.map((detail) => (
                      <li key={detail?.id} className="order-card">
                        <p>
                          <strong>Name:</strong> {detail?.name}
                        </p>
                        <p>
                          <strong>Price:</strong> ₹{detail?.price}
                        </p>
                      </li>
                    ))}
                  </ul>
                ) : (
                  <p>No order details available.</p>
                )}
              </div>

              <div className="employee-details">
                <h4>Employee</h4>
                <p>
                  <strong>Full Name:</strong> {service.employee?.fullName}
                </p>
              </div>
            </div>
          ))
        ) : (
          <p>No services found for the given Booking ID.</p>
        )}
      </div>
    </div>
  );
};

export default UserDashboard;
