import React from 'react';
import './loader.css';
const Loader = () => (
    <div className="loader">
        <div className="spinner"></div>
        <p>Loading...</p>
    </div>
);

export default Loader;
