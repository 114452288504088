import React, { useEffect, useState } from "react";
import $ from "jquery";
import "datatables.net";
import "datatables.net-dt/css/jquery.dataTables.css";
import "datatables.net-responsive/js/dataTables.responsive.min.js";
import "datatables.net-responsive-dt/css/responsive.dataTables.min.css";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import "../index.css";

const DataTable = ({ children, tableName }) => {
  const [dataTable, setDataTable] = useState(null);

  console.log("children is");
  console.log(tableName);
  useEffect(() => {
    const dataList = $("#example")
      .DataTable({
        responsive: true,
        dom: "Bfrtip",
        buttons: [
          {
            extend: "csv",
            // filename: "data",
            filename: `${tableName}`,
          },
        ],
      })
      .columns.adjust()
      .responsive.recalc();
    console.log(dataList);

    setDataTable(dataList);
    $(".buttons-csv").addClass(
      "border border-[#FBB033] bg-black text-[#FBB033] font-be-jost-pro font-medium text-sm rounded-md px-10 py-2"
    );
  }, []);

  const downloadCSV = (e) => {
    try {
      dataTable.button(".buttons-csv").trigger();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="container w-full md:w-4/5 xl:w-[98%] mx-auto px-2">
      {/* <button
          className="border border-[#FBB033] bg-black text-[#FBB033] font-be-jost-pro font-medium text-sm rounded-md px-10 py-2"
          onClick={downloadCSV}
        >
          Download CSV
        </button> */}
      <div
        id="recipients"
        className="p-8 mt-6 lg:mt-0 rounded shadow bg-[#0c0b0b]"
      >
        <table
          id="example"
          className="stripe hover"
          style={{ width: "100%", paddingTop: "1em", paddingBottom: "1em" }}
        >
          {children}
        </table>
      </div>
    </div>
  );
};

export default DataTable;
