import React, { useState, useEffect } from "react";
import Layout from "./Components/Layout";
import Sidebar from "./Components/Sidebar";
import UploadBannerModal from "./Components/AdminBanner/UploadBannerModal";
import { AiOutlineDelete } from "react-icons/ai";
import { useQuery, gql, useMutation } from "@apollo/client";
import Loader from "./Loader";
const GET_BANNERS = gql`
  query {
    getBanners {
      status
      message
      bannerList {
        id
        image
      }
    }
  }
`;

const DELETE_BANNER = gql`
  mutation deleteBanner($data: ID!) {
    deleteBanner(data: $data) {
      status
      message
    }
  }
`;

const Banners = () => {
  const [showModal, setShowModal] = useState(false);
  const [isFetched, setIsFetched] = useState(false);
  const [dataList, setDataList] = useState(false);
  console.log("process.env.REACT_APP_BASE_URL");
  console.log(process.env.REACT_BASE_API_ENDPOINT);
  // const getBanners = useQuery(GET_BANNERS);
  const { loading, error, data } = useQuery(GET_BANNERS);
 
  const [deleteBanner, { deleteLoading, deletError }] =
    useMutation(DELETE_BANNER);

  useEffect(() => {
    try {
      console.log("error is");
      console.log(error);
      console.log("data is");
      console.log(data.getBanners.bannerList);

      setDataList(data.getBanners.bannerList);
      setIsFetched(true);
    } catch (err) {
      console.log(err);
    }
  }, [data]);
  if(loading||deleteLoading||!isFetched) return <Loader />
  if (error) {
    return <p className="text-red-500">An error occurred while fetching banners.</p>;
  }
  const handleRemoveRow = (dataId) => {
    deleteBanner({
      variables: { data: dataId },
    }).then((res) => {
      // window
      console.log(" delete res is");
      console.log(res);
      if (res.data.deleteBanner.status === "SUCCESS") {
        window.location.reload();
      }
    });
  };

  // console.log(getBanners);
  return (
    <div className=" h-screen flex">
      <Sidebar />
      {isFetched && (
        <Layout>
          <div className="flex flex-col">
            <p
              className={` text-[#FBB034] rounded-sm cursor-default text-2xl px-2 py-1 font-normal font-be-jost-pro text-center md:text-left`}
            >
              Current Banners
            </p>
            <div className="flex justify-between items-center flex-wrap w-[90%] lg:w-4/5">
              {/* <img
              // src="/Assets/banner-1.png"
              src={`http://localhost:3030/uploads/1688537417310.png`}
              className="w-full lg:w-[45%] my-4 mx-3"
            />
            <img
              src="/Assets/banner-1.png"
              className="w-full lg:w-[45%] my-4 mx-3"
            />
            <img
              src="/Assets/banner-1.png"
              className="w-full lg:w-[45%] my-4 mx-3"
            />
            <img
              src="/Assets/banner-1.png"
              className="w-full lg:w-[45%] my-4 mx-3"
            /> */}
              {
                dataList.length > 0
                  ? dataList.map((item, i) => (
                      <div
                        className=" lg:w-[45%] my-4 mx-3"
                        style={{ position: "relative" }}
                      >
                        <img
                          src={`http://api.barbera.in` + item.image}
                          // className="w-full lg:w-[45%] my-4 mx-3"
                          className="w-full"
                        />
                        <AiOutlineDelete
                          className="text-[#FBB033] text-2xl cursor-pointer"
                          style={{
                            position: "absolute",
                            top: 2,
                            right: 2,
                            zIndex: 100,
                          }}
                          onClick={() => handleRemoveRow(item.id)}
                        />
                      </div>
                    ))
                  : ""
                // :(
                //   <p
                //   className={` text-[#FBB034] rounded-sm cursor-default text-2xl px-2 py-1 font-normal font-be-jost-pro text-center md:text-left`}
                // >
                //   Upload Banners
                // </p>
                // )
              }
            </div>
            <button
              className="mt-10 lg:ml-3 text-[#FBB033] font-be-jost-pro font-medium text-sm border border-[#FBB033] rounded-md py-2 px-3 w-full lg:w-[10%]"
              onClick={() => setShowModal(true)}
            >
              Upload Banner
            </button>
          </div>
        </Layout>
      )}
      {showModal && <UploadBannerModal setShowModal={setShowModal} />}
    </div>
  );
};

export default Banners;
