import React from "react";
import { useQuery, gql } from "@apollo/client";
import "./EmployeeDashboard.css";
import Loader from "./Loader";
import { useParams, useLocation } from "react-router-dom";
import { useState } from "react"; // Import useParams for accessing route parameters
const GET_Employee_DASHBOARD = gql`
  query getEmployeeDashboard($data: EmployeeDashboardInput) {
    getEmployeeDashboard(data: $data) {
      message
      earnings
      cashEarnings
      totalServices {
        price
        totalPrice
        address
        bookingDate
        paymentType
        status
        timeSlot
        totalTime
        bookingId
        orderDetail {
          id
          name
          price
        }
        employee {
          id
          fullName
        }
      }
      inventoryList {
        id
        price
        employeeInventory {
          id
          name
          description
        }
        createdAt
      }
      inventoryTotal {
        id
        price
        employeeInventory {
          id
          name
          description
        }
        createdAt
      }
    }
  }
`;

const EmployeeDashboard = () => {
  //usestate for the toggling between demand of data
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [selectedData, setSelectedData] = useState("totalServices");
  const [searchBookingId, setSearchBookingId] = useState("");
  const [searchId, setSearchId] = useState("");
  const [searchID, setSearchID] = useState("");
  const { employeeId } = useParams();
  const location = useLocation();
  const { employeeData } = location.state;
  console.log(employeeData);

  //fetch data usign query
  const { loading, error, data } = useQuery(GET_Employee_DASHBOARD, {
    variables: {
      data: { employeeId },
    },
    enabled: Boolean(employeeId),
  });

  if (loading) return <Loader />; // Show Loader while loading;
  if (error) return <p>Error:{error.message}</p>
  console.log("what is this above or beside");

  //extract the data from the respose
  const { message, totalServices, inventoryList, inventoryTotal } =
    data.getEmployeeDashboard;

  //   console.log(totalServices);

  const services = totalServices.map((service) => ({
    price: service?.price ?? 0,
    totalPrice: service?.totalPrice ?? 0,
    address: service?.address ?? "Not Provided",
    bookingDate: service?.bookingDate ?? "Not Available",
    paymentType: service?.paymentType ?? "Not Specified",
    status: service?.status ?? "Unknown",
    timeSlot: service?.timeSlot ?? "Not Available",
    totalTime: service?.totalTime ?? "Not Available",
    bookingId: service?.bookingId ?? "Not Available",
    orderDetail:
      service?.orderDetail?.map((detail) => ({
        id: detail?.id ?? "N/A",
        name: detail?.name ?? "Unknown",
        price: detail?.price ?? 0,
      })) ?? [],
    employee: {
      id: service?.employee?.id ?? "N/A",
      fullName: service?.employee?.fullName ?? "Unknown",
    },
  }));

  // Map inventoryList and assign to inventorylist
  const inventorylist = inventoryList.map((item) => ({
    id: item?.id ?? "N/A",
    price: item?.price ?? 0,
    employeeInventory: {
      id: item?.employeeInventory?.id ?? "N/A",
      name: item?.employeeInventory?.name ?? "Unknown",
      description: item?.employeeInventory?.description ?? "No Description",
    },
    createdAt: item?.createdAt ?? "Not Available",
  }));

  // // Map inventoryTotal and assign to inventorytotal
  const inventorytotal = inventoryTotal.map((item) => ({
    id: item?.id ?? "N/A",
    price: item?.price ?? 0,
    employeeInventory: {
      id: item?.employeeInventory?.id ?? "N/A",
      name: item?.employeeInventory?.name ?? "Unknown",
      description: item?.employeeInventory?.description ?? "No Description",
    },
    createdAt: item?.createdAt ?? "Not Available",
  }));
  //handle the click on buttons
  const handleButtonClick = (type) => {
    setSelectedData(type);
  };


  // Handle date input changes
  const handleStartDateChange = (e) => setStartDate(e.target.value);
  const handleEndDateChange = (e) => setEndDate(e.target.value);

  // Helper function to check if a date is within the range
  const isWithinDateRange = (date) => {
    const timestamp = new Date(date).getTime();
    const startTimestamp = startDate ? new Date(startDate).getTime() : null;
    const endTimestamp = endDate ? new Date(endDate).getTime() : null;

    if (startTimestamp && timestamp < startTimestamp) return false;
    if (endTimestamp && timestamp > endTimestamp) return false;
    return true;
  };

  const filteredServices = services.filter((service) => {
    const matchesId = service.bookingId.toLowerCase().includes(searchBookingId.toLowerCase());
    const matchesDate = isWithinDateRange(service.bookingDate);
    return matchesId && matchesDate;
  });
  const filteredList = inventorylist.filter((list) => {
    const matchesId = list.id.toLowerCase().includes(searchId.toLowerCase())
    const matchesDate = isWithinDateRange(list.date);
    return matchesId && matchesDate;

  });
  const filteredTotal = inventorytotal.filter((total) => {
    const matchesId = total.id.toLowerCase().includes(searchID.toLowerCase())
    const matchesDate = isWithinDateRange(total.date);
    return matchesId && matchesDate;
  });

  // Handle input change for filter for totalservices
  const handleSearchChange1 = (e) => {
    setSearchBookingId(e.target.value);
  };
  //Handle input change for filter for inventorylist
  const handleSearchChange2 = (e) => {
    setSearchId(e.target.value);
  };
  //Handle input change for filter for inventorytotal
  const handleSearchChange3 = (e) => {
    setSearchID(e.target.value);
  };
  // Helper function to display the data as cards
  const renderCards = (dataType) => {
    switch (dataType) {
      case "totalServices":
        // Check if services array is empty
        if (services.length === 0) {
          return <p className="nodata">No services available for the NAME:{employeeData.fullName} </p>;
        }
        return (
          <div>
            <div className="filter-container">
              <label htmlFor="id-filter">Filter by Booking ID: </label>
              <input
                type="text"
                id="id-filter"
                value={searchBookingId}
                onChange={handleSearchChange1}
                placeholder="Enter Booking ID"
              />
              
            </div>

            {filteredServices.map((service) => (
              <div key={service.bookingId} className="service-card">
                <h3>
                  <strong>Booking ID:</strong> {service.bookingId}
                </h3>
                <p>
                  <strong>Employee Name:</strong> {service.employee.fullName}
                </p>
                <p>
                  <strong>Employee ID:</strong> {service.employee.id}
                </p>
                <p>
                  <strong>Price:</strong> ₹{service.price}
                </p>
                <p>
                  <strong>Address:</strong>
                  <span className="address">
                    {service.address.split("\n").map((line, index) => (
                      <span key={index}>
                        {line}
                        <br />
                      </span>
                    ))}
                  </span>
                </p>
                <p>
                  <strong>Booking Date:</strong> {service.bookingDate}
                </p>
                <p>
                  <strong>Payment Type:</strong> {service.paymentType}
                </p>
                <p>
                  <strong>Status:</strong> {service.status}
                </p>
                <p>
                  <strong>Time Slot:</strong> {service.timeSlot}
                </p>
                <p>
                  <strong>Total Time:</strong> {service.totalTime}
                </p>
                <div>
                  <strong>Order Details:</strong>
                  {service.orderDetail.map((detail) => (
                    <div key={detail.id} className="order-detail">
                      <p>
                        <strong>Order ID:</strong> {detail.id}
                      </p>
                      <p>
                        <strong>Order Name:</strong> {detail.name}
                      </p>
                      <p>
                        <strong>Order Price:</strong> ₹{detail.price}
                      </p>
                    </div>
                  ))}
                </div>

              </div>
            ))}
          </div>
        );


      case "inventoryList":
        // Check if inventorylist array is empty
        if (inventorylist.length === 0) {
          return <p className="nodata">No inventory items available for the {employeeData.fullName} </p>;
        }
        return (

          <div>
            <div className="filter-container">
              <label htmlFor="id-filter">Filter by ID: </label>
              <input
                type="text"
                id="id-filter"
                value={searchId}
                onChange={handleSearchChange2}
                placeholder="Enter ID"
              />
            </div>

            {filteredList.map((inventory) => (
              <div key={inventory.id} className="inventory-card">
                <h3>
                  <strong>Inventory ID:</strong> {inventory.id}
                </h3>
                <p>
                  <strong>Price:</strong> ₹{inventory.price}
                </p>
                <p>
                  <strong>Created At:</strong> {new Date(inventory.createdAt).toLocaleString()}
                </p>
                <strong>Employee Inventory Details:</strong>
                <div className="employee-inventory">

                  <p>
                    <strong>Employee Inventory ID:</strong> {inventory.employeeInventory.id}
                  </p>
                  <p>
                    <strong>Name:</strong> {inventory.employeeInventory.name}
                  </p>
                  <p>
                    <strong>Description:</strong> {inventory.employeeInventory.description}
                  </p>
                </div>


              </div>

            ))};
          </div>
        )

      case "inventoryTotal":
        // Check if inventorytotal array is empty
        if (inventorytotal.length === 0) {
          return <p className="nodata">No total inventory items available for the name: {employeeData.fullName} </p>;
        }
        return (
          <div>
            <div className="filter-container">
              <label htmlFor="id-filter">Filter by ID: </label>
              <input
                type="text"
                id="id-filter"
                value={searchId}
                onChange={handleSearchChange3}
                placeholder="Enter ID"
              />
            </div>

            {filteredTotal.map((inventory) => (
              <div key={inventory.id} className="inventory-total-card">
                <h3>
                  <strong>Inventory ID:</strong> {inventory.id}
                </h3>
                <p>
                  <strong>Price:</strong> ₹{inventory.price}
                </p>
                <p>
                  <strong>Created At:</strong> {new Date(inventory.createdAt).toLocaleString()}
                </p>
                <h4>Employee Inventory Details:</h4>
                <div className="employee-inventory">
                  <h4>Employee Inventory Details:</h4>
                  <p>
                    <strong>Employee Inventory ID:</strong> {inventory.employeeInventory.id}
                  </p>
                  <p>
                    <strong>Name:</strong> {inventory.employeeInventory.name}
                  </p>
                  <p>
                    <strong>Description:</strong> {inventory.employeeInventory.description}
                  </p>
                </div>
              </div>
            ))};
          </div>
        )


      default:
        return null;
    }
  };

  return (
    <div className="dashboard-container">
      <h2>Employee Dashboard</h2>
      <h4>
        <strong>Name: </strong> {employeeData.fullName}

      </h4>
      <h4>
        <strong>Employee ID: </strong> {services[0].employee.id}
      </h4>
      {/* Buttons for selecting the data to display */}
      <div className="button-group">
        <button
          className={selectedData === "totalServices" ? "active" : ""}
          onClick={() => handleButtonClick("totalServices")}
        >
          Total Services
        </button>
        <button
          className={selectedData === "inventoryList" ? "active" : ""}
          onClick={() => handleButtonClick("inventoryList")}
        >
          Inventory List
        </button>
        <button
          className={selectedData === "inventoryTotal" ? "active" : ""}
          onClick={() => handleButtonClick("inventoryTotal")}
        >
          Inventory Total
        </button>
      </div>
      <div className="filter-container">
        <label >Start Date: </label>
        <input
          type="date"
          id="id-filter"
          value={startDate}
          onChange={handleStartDateChange}
        />
        <label >End Date: </label>
        <input
          type="date"
          id="id-filter"
          value={endDate}
          onChange={handleEndDateChange}
        />
      </div>

      {/* Display the selected data */}
      <div className="cards-container">{renderCards(selectedData)}</div>
    </div>
  );
};

export default EmployeeDashboard;
