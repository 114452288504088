import React, { useState,useEffect } from "react";
import Sidebar from "./Components/Sidebar";
import Layout from "./Components/Layout";
// import DataTable from "react-data-table-component";
import DataTable from "./Components/DataTable";
import "./index.css";
import CodeManagementTable from "./Components/CodeManagament/CodeManagementTable";
import CodeManagementForm from "./Components/CodeManagament/CodeManagementForm";
import { useQuery, gql } from "@apollo/client";
import Loader from "./Loader";
const GET_PROMOCODE= gql`
query{
  getPromocode{
    status
    message
   promoCodeList{
    id
    name
    promocode
    discount
    type
  }
  }
}
`

const tempData = [
  {
    name: "New User",
    type: "New User",
    off: "20",
    promoCode: "new001",
  },
  {
    name: "HDFC Card",
    type: "Credit Card",
    off: "10",
    promoCode: "Barbera001",
  },
  {
    name: "Discount 50",
    type: "Discount",
    off: "25",
    promoCode: "Discount25",
  },
  {
    name: "Discount 50",
    type: "Discount",
    off: "25",
    promoCode: "Discount25",
  },
  {
    name: "Discount 50",
    type: "Discount",
    off: "25",
    promoCode: "Discount25",
  },
  {
    name: "Discount 50",
    type: "Discount",
    off: "25",
    promoCode: "Discount25",
  },
];

const CodeManagement = () => {
  // const promocodes = useQuery(GET_PROMOCODE);
  const {data,loading,error} = useQuery(GET_PROMOCODE);
  // console.log('data is');
  // console.log(promocodes);
  // console.log(promocodes.error,promocodes.loading,promocodes.data);
  // console.log(promocodes.data.getPromocode.promoCodeList);
  const [dataList, setDataList] = useState([]);
  const [openEditForm, setEditForm] = useState(false);
  const [editData, setEditData] = useState({});
  const [isFetched, setIsFetched] = useState(false);

  useEffect(()=>{
    try{
    
    console.log('data is');
    console.log(data);
    
   setDataList(data.getPromocode.promoCodeList);
    setIsFetched(true);
    }catch(err){
      console.log(err);
    }
  },[data])
if(loading) return <Loader/>
  return (
    <>
      {
        isFetched &&
        (
          <div className=" h-screen flex">
        <Sidebar />
        <Layout>
          {openEditForm ? (
            <CodeManagementForm
              setEditCodeManagementForm={setEditForm}
              editData={editData}
            />
          ) : (
            <CodeManagementTable data={dataList}setEditForm={setEditForm}setEditData={setEditData}/>
          )}
        </Layout>
      </div>
        )
      }
    </>
  );
};

export default CodeManagement;
